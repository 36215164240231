import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchDeleteRequest, fetchGetRequest, fetchPostRequest} from '../../libs/network';
import endpoints from '../../utils/endpoints';
import toast from 'react-hot-toast';
import { deleteModalHide } from './modals';

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  foodItems: null,
};

export const getfoodItems = createAsyncThunk(
  'foodItems/getfoodItems',
  async ({ searchQuery, orderBy, orderType }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getfoodItems,
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
            keywords: searchQuery || undefined,
            order_by: orderBy || undefined,
            order_type: orderType || undefined
          },
        },
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to get Food");
      console.log('get food item err', JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const deletefoodItems = createAsyncThunk(
  'foodItems/deletefoodItems',
  async (foodItemId, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deletefoodItems(foodItemId),
      );
      toast.success(response?.data?.message || "Food removed Successfully");
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log('food item', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to remove Food");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

export const ignorefoodItems = createAsyncThunk(
  'foodItems/ignorefoodItems',
  async (foodItemId, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.ignorefoodItems(foodItemId),
      );
      toast.success(response?.data?.message || "Food Report Ignored Successfully");
      return thunkAPI.fulfillWithValue(response?.data);

    } catch (err) {
      console.log('food item', JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to ignore Food Report");

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const {reducer, actions} = createSlice({
  name: 'foodItems',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(getfoodItems.pending, state => {
        state.isLoading = true;
      })
      .addCase(getfoodItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.foodItems = action?.payload?.food_reports;
        
      })
      .addCase(getfoodItems.rejected, state => {
        state.isLoading = false;
      })
      
      .addCase(deletefoodItems.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(deletefoodItems.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(deletefoodItems.rejected, state => {
        state.isPopupLoading = false;
      })

      .addCase(ignorefoodItems.pending, state => {
        state.isPopupLoading = true;
      })
      .addCase(ignorefoodItems.fulfilled, (state, action) => {
        state.isPopupLoading = false;
        
      })
      .addCase(ignorefoodItems.rejected, state => {
        state.isPopupLoading = false;
      }),

});

export default reducer;
export const {} = actions;