import React, { useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import "./menu.scss";
import Loading from "../../../components/loading";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getAdminFoodCategoryById, getFoodCategoryById } from "../../../../store/reducers/foodCategoryById";
import { constant } from "../../../constants";
import { createAdminFoodCategory, createFoodCategory, updateAdminFoodCategory, updateFoodCategory } from "../../../../store/reducers/foodCategory";
import { selectRestaurantLocationData, selectfoodCategoryByIdData, selectfoodCategoryData } from "../../../../store/selectors/dashboardSelectors";
import { userData } from "../../../../store/selectors/authSelectors";

const CategoryPopup = ({
  isOpen,
  onClose,
  isEditMode,
  selectedCategoryForCreate = "",
  categoryId,
  getFoodsData,
  restaurantIdFromFood,
}) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const foodCategoryData = useSelector(selectfoodCategoryData);
  const foodCategoryByIdData = useSelector(selectfoodCategoryByIdData);
  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantId = restaurantIdFromFood ? restaurantIdFromFood : restaurantDetailData?.restaurantId;
  const user = useSelector(userData);

  useEffect(() => {
    if (isEditMode && categoryId) {
      const fetchCategoryByIdFunction =
        user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
          ? () => dispatch(getFoodCategoryById(categoryId))
          : () => dispatch(getAdminFoodCategoryById(categoryId));
      fetchCategoryByIdFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            if (response) {
              formikRef?.current?.setFieldValue(
                "categoryTitle",
                response?.payload?.food_category?.categoryName
              );
            }
            return;

          default:
            return;
        }
      });
    }
  }, [dispatch, isEditMode, categoryId]);

  const handleCategory = async (values) => {
    const payload = {
      foodCategoryName: values.categoryTitle,
      ...(user.userType !== "Restaurant" || user.userSettings?.isSuperAdmin ? { restaurantId: restaurantId } : {})
    }; 

    if (isEditMode && categoryId) {
        const updateFoodCategoryFunction =
          user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
            ? () => dispatch(updateFoodCategory({ payload, categoryId }))
            : () => dispatch(updateAdminFoodCategory({ payload, categoryId }));
      updateFoodCategoryFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            toast.success(
              response?.payload?.message || "Category updated successfully"
            );
            onClose();
            getFoodsData();
            return;

          default:
            return;
        }
      });
    } else {
      const createFoodCategoryFunction =
        user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
          ? () => dispatch(createFoodCategory(payload))
          : () => dispatch(createAdminFoodCategory({ payload, restaurantId }));
      createFoodCategoryFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            toast.success(
              response?.payload?.message || "Category created successfully"
            );
            onClose();
            // getFoodsData();
            return;

          default:
            return;
        }
      });
    }
  };

  return (
    isOpen && (
      <div className="popup zIndex999 overlapPop">
        <div className="popup-content editCategorypop">
          <h2>{isEditMode ? "Edit a Category" : "Add a Category"}</h2>
          <div>
            {foodCategoryByIdData?.isLoading && (
              <div className="tableLoader">
                <div
                  style={{ borderRadius: "30px", top: "0" }}
                  className="spinnerLoad"
                >
                  <Loading></Loading>
                </div>
              </div>
            )}
            <Formik
              innerRef={formikRef}
              initialValues={{ categoryTitle: selectedCategoryForCreate }}
              validationSchema={Yup.object().shape({
                categoryTitle: Yup.string().required(
                  "Category title is required"
                ).max(50, "Category cannot exceed 50 characters"),
              })}
              onSubmit={(values, { setSubmitting }) =>
                handleCategory(values, setSubmitting)
              }
            >
              {({ isSubmitting, isValid, setFieldValue }) => {
                return (
                  <Form>
                    <div className="flexRow">
                      <div className="formCol withFull">
                        <div className="form-group">
                          <label htmlFor="categoryTitle">Category Title*</label>
                          <Field
                            type="text"
                            name="categoryTitle"
                            id="categoryTitle"
                            placeholder="Category Title"
                            component={Input}
                            onChange={(e) => {
                              setFieldValue("categoryTitle", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      type="submit"
                      className="cancleBtn"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="btn"
                    >
                      {foodCategoryData?.isPopupLoading && <Loading></Loading>}
                      {isSubmitting
                        ? "Submitting..."
                        : isEditMode
                        ? "Done"
                        : "Add Category"}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    )
  );
};

export default CategoryPopup;
