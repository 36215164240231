import React from "react";
import { Formik, Form } from "formik";
import Button from "../../../components/button";
import "./deal.scss";
import { useNavigate } from "react-router-dom";
import EditDealPopup from "./editDealPopup";
import { selectIsEditDealModalOpen } from "../../../../store/selectors/modalSelectors";
import { useDispatch, useSelector } from "react-redux";
import { editDealModalHide, editDealModalOpen } from "../../../../store/reducers/modals";
import { userData } from "../../../../store/selectors/authSelectors";

const SuccessPopup = ({id}) => {
  const dispatch = useDispatch();
  const isEditDealPopupOpen = useSelector(selectIsEditDealModalOpen);
  const user = useSelector(userData);
  const navigate = useNavigate();

  const handleDismiss = () => {
    if (user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin) {
      navigate("/dashboard/deals");
    } else {
      navigate(`/dashboard/restaurant-location-owner/view-detail/${id}`, {
        state: { activeTab: "deal-tab" },
      });
    }
  };

  return (
    <div className="popup">
      <div className="popup-content2">
        <h2>Success!</h2>
        <Formik>
          <Form>
            <p style={{ lineHeight: "1.7", textAlign: "center" }}>
              Your deal has been created! We will notify the people we think
              will like the menu items in this deal. You will be able to remove
              or edit your deal anytime.
            </p>
            <div style={{ marginTop: 50 }} className="flxendBtn">
              <Button className="dealCancleBtn" type="button" onClick={handleDismiss}>
                Dismiss
              </Button>
              <Button
                type="button"
                className="dealsuccessBTn"
                onClick={() => dispatch(editDealModalOpen())}
              >
                View Deal
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
      {isEditDealPopupOpen && (
        <EditDealPopup onClose={() => dispatch(editDealModalHide())} />
      )}
    </div>
  );
};

export default SuccessPopup;
