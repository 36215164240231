import React, { useEffect, useState } from "react";
import "../restaurant-locations.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import * as Yup from "yup";
import toast from "react-hot-toast";
import ImageUploader from "./imageUploader";
import ChangePassWord from "./changePassword";
import Loading from "../../../../components/loading";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getFoodCategoryForRestaurant,
  getRestaurantFoodCategoryForAdmin,
} from "../../../../../store/reducers/foodCategory";
import { constant } from "../../../../constants";
import {
  selectRestaurantData,
  selectRestaurantDetailsData,
  selectfoodCategoryData,
} from "../../../../../store/selectors/dashboardSelectors";
import {
  selectIsDeleteRestaurantProfileModalOpen,
  selectIschangePasswordModalOpen,
} from "../../../../../store/selectors/modalSelectors";
import {
  changePasswordModalHide,
  changePasswordModalOpen,
  deleteRestaurantProfileModalHide,
  deleteRestaurantProfileModalOpen,
} from "../../../../../store/reducers/modals";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../../../../components/delete-popup";
import { userData } from "../../../../../store/selectors/authSelectors";
import { logoutUser } from "../../../../../store/reducers/authSlice";
import { deleteRestaurantProfile } from "../../../../../store/reducers/restaurantDetail";
import { removeAccessRestaurant } from "../../../../../store/reducers/restaurant";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #ced4da",
    borderRadius: "4px",
    minHeight: "38px",
    boxShadow: "none",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "8px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#ffc107",
    borderRadius: "20px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#ffffff",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    color: "#ffc107",
    marginRight: "5px",
    marginLeft: "5px",
    marginTop: "5px",
    marginBottom: "5px",
    width: "20px",
    height: "20px",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
};

function EditRestaurantProfiles({
  closePopup,
  handleEditRestaurantProfiles,
  restaurantName,
  restaurantEmail,
  restaurantImages,
  restaurantCategory,
  popuploading,
  restaurantLocationId,
  restaurantAccountStatus,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userData);
  const foodCategoryData = useSelector(selectfoodCategoryData);
  const [foodCategories, setFoodCategories] = useState([]);
  const isChangePasswordPopUpShow = useSelector(
    selectIschangePasswordModalOpen,
  );
  const isDeletePopUpShow = useSelector(
    selectIsDeleteRestaurantProfileModalOpen,
  );
  const restaurantDetailData = useSelector(selectRestaurantDetailsData);
  const restaurantData = useSelector(selectRestaurantData);

  const initialValues = {
    restaurantName: restaurantName,
    email: restaurantEmail || "",
    category: restaurantCategory.map((category) => ({
      value: category?.restaurantCategoryId,
      label: category?.restaurantCategoryName,
    })),
  };

  const validationSchema = Yup.object().shape({
    restaurantName: Yup.string()
      .required("Restaurant Name is required")
      .max(255, "Restaurant Name cannot exceed 255 characters"),
    category: Yup.array()
      .of(
        Yup.object({
          label: Yup.string().trim(),
          value: Yup.string().trim(),
        }),
      )
      .min(1, "Select at least one category"),
    email: restaurantEmail
      ? Yup.string()
          .email("Invalid email")
          .required("Email Address is required")
          .trim()
      : Yup.string().nullable(),
  });
  //========================update-restaurant========================

  const handleSubmitData = (values) => {
    handleEditRestaurantProfiles(values);
  };

  useEffect(() => {
    const fetchFunction =
      user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
        ? getFoodCategoryForRestaurant
        : getRestaurantFoodCategoryForAdmin;
    dispatch(fetchFunction()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setFoodCategories(
            response?.payload?.restaurantCategories?.map((item) => {
              return {
                value: item?.restaurantCategoryId,
                label: item?.restaurantCategoryName,
              };
            }),
          );
          return;

        default:
          return;
      }
    });
    dispatch(changePasswordModalHide());
    dispatch(deleteRestaurantProfileModalHide());
    return () => {
      dispatch(changePasswordModalHide());
      dispatch(deleteRestaurantProfileModalHide());
    };
  }, [dispatch]);

  const removeAccess = async (restaurantLocationId) => {
    const payload = {
      account_status:
        restaurantAccountStatus === "Approved" ? "Suspended" : "Approved",
    };
    const removeFunction =
      user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
        ? () => dispatch(deleteRestaurantProfile())
        : () =>
            dispatch(
              removeAccessRestaurant({
                payload,
                restaurant_id: restaurantLocationId,
              }),
            );
    await removeFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          if (
            user.userType === "Restaurant" &&
            !user.userSettings?.isSuperAdmin
          ) {
            toast.success(
              response?.data?.message || "Restaurant deleted Successfully",
            );
            dispatch(logoutUser());
          } else {
            toast.success(
              response?.payload?.message ||
                (restaurantAccountStatus === "Approved"
                  ? "Restaurant Suspended successfully"
                  : "Restaurant Approved successfully"),
            );
            navigate("/dashboard/restaurant");
          }
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Edit Restaurant Profile</h2>
        <i onClick={closePopup} className="bx bx-x popClose"></i>
        <div>
          {(foodCategoryData?.isLoading ||
            !(restaurantName && restaurantCategory && restaurantImages)) && (
            <div className="tableLoader">
              <div
                style={{ borderRadius: "30px", top: "0" }}
                className="spinnerLoad"
              >
                <Loading></Loading>
              </div>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmitData(values)}
          >
            {({ isSubmitting, isValid, setFieldValue, values }) => {
              return (
                <Form>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="restaurantName">Restaurant Name</label>
                        <Field
                          type="text"
                          name="restaurantName"
                          id="restaurantName"
                          placeholder="Restaurant Address"
                          component={Input}
                        />
                      </div>
                    </div>
                    {restaurantEmail && (
                      <div className="formCol">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="text"
                            name="email"
                            id="email"
                            placeholder="example@gmail.com"
                            component={Input}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="category">Restaurant Category</label>
                        <Select
                          options={foodCategories}
                          styles={customStyles}
                          isMulti={true}
                          placeholder="Select Category"
                          value={values.category}
                          isSearchable={true}
                          name="category"
                          className="catselectDropcustom"
                          onChange={(e) => {
                            console.log(e);
                            setFieldValue(
                              "category",
                              e.map((item) => {
                                return {
                                  value: item.value,
                                  label: item.label,
                                };
                              }),
                            );
                          }}
                        />
                        <div className="error-wrapper">
                          <ErrorMessage name="category" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flexRow">
                    <div className="formCol fullcolWidth100">
                      <div className="form-group imgUploaders">
                        <label style={{ marginBottom: "10px" }}>
                          Company Wide Photos
                        </label>
                        <ImageUploader
                          companyWideImages={restaurantImages}
                          handleImage={(image) =>
                            setFieldValue("images", image)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flxendrightBtn resUploaedBtns">
                    {user.userType === "Restaurant" &&
                      !user.userSettings?.isSuperAdmin && (
                        <div
                          className="raBtn"
                          style={{ marginRight: "3%" }}
                          onClick={() => dispatch(changePasswordModalOpen())}
                        >
                          Change password
                        </div>
                      )}
                    {restaurantEmail && (
                      <div
                        className="popdealCancleBtn mb0"
                        style={{ marginRight: "3%" }}
                        onClick={() =>
                          dispatch(deleteRestaurantProfileModalOpen())
                        }
                      >
                        {user.userType === "Restaurant" &&
                        !user.userSettings?.isSuperAdmin
                          ? "Delete"
                          : restaurantAccountStatus === "Approved"
                          ? "Remove Access"
                          : "Approve Access"}
                      </div>
                    )}
                    <Button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="btn"
                    >
                      {popuploading && <Loading></Loading>}
                      Done
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {isChangePasswordPopUpShow && (
        <ChangePassWord
          closePopup={() => dispatch(changePasswordModalHide())}
        />
      )}
      {isDeletePopUpShow && (
        <DeletePopup
          closePopup={() => dispatch(deleteRestaurantProfileModalHide())}
          handleDelete={() => removeAccess(restaurantLocationId)}
          popuploading={
            user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
              ? restaurantDetailData?.isDeletePopupLoading
              : restaurantData?.isPopupLoading
          }
          title={
            user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
              ? "Delete Restaurant"
              : restaurantAccountStatus === "Approved"
              ? "Remove Access"
              : "Approve Access"
          }
          description={
            user.userType ===
              "Are you sure you want to delete restaurant? All restaurant data will be erased and this process is irreversible." &&
            !user.userSettings?.isSuperAdmin
              ? "Delete Restaurant"
              : restaurantAccountStatus === "Approved"
              ? "Are you sure you want to remove this restaurant's access? They won't be able to manage their restaurant anymore."
              : "Are you sure you want to approve this restaurant's access? They will now be able to manage their restaurant."
          }
          buttonName={
            user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
              ? "Delete"
              : restaurantAccountStatus === "Approved"
              ? "Remove Access"
              : "Approve Access"
          }
        />
      )}
    </div>
  );
}

export default EditRestaurantProfiles;
