import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";

const initialState = {
  isFetchingRestaurantAllAddress: false,
  restaurantAllAddress: [],
};

export const getRestaurantAllAddress = createAsyncThunk(
  "restaurantAddress/getRestaurantAllAddress",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        payload?.restaurantId
          ? endpoints.dashboard.getRestaurantAllAddressForAdmin(
              payload?.restaurantId,
            )
          : endpoints.dashboard.getRestaurantAllAddressForRestaurant,
        {
          params: {
            page: payload?.page,
            size: payload?.size,
            keywords: payload?.keywords,
          },
        },
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log(
        "get restaurant all address err",
        JSON.stringify(err, null, 4),
      );

      return thunkAPI.rejectWithValue(err?.data);
    }
  },
);

const { reducer, actions } = createSlice({
  name: "restaurantAddress",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getRestaurantAllAddress.pending, (state) => {
        state.isFetchingRestaurantAllAddress = true;
      })
      .addCase(getRestaurantAllAddress.fulfilled, (state, action) => {
        state.isFetchingRestaurantAllAddress = false;
        state.restaurantAllAddress = action?.payload?.addresses;
      })
      .addCase(getRestaurantAllAddress.rejected, (state) => {
        state.isFetchingRestaurantAllAddress = false;
      }),
});

export default reducer;
export const {} = actions;
