import React, { useState, useEffect } from "react";
import Layout from "../../../layouts/dashboard-layout";
import Loading from "../../../components/loading";
import { getAllRestaurant } from "../../../../store/reducers/restaurantMerger";
import { useDispatch, useSelector } from "react-redux";
import { selectRestaurantMergerData } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import "./restaurant-merger.scss";
import {
  mergeRestaurantModalHide,
  mergeRestaurantModalOpen,
} from "../../../../store/reducers/modals";
import { selectIsMergeRestaurantModalOpen } from "../../../../store/selectors/modalSelectors";
import ReactPaginate from "react-paginate";
import MergeRestaurantPopup from "./mergeRestaurant-popup";
import { useNavigate } from "react-router-dom";
import { restaurantId } from "../../../../store/reducers/restaurantLocationDetail";

const RestaurantMerger = () => {
  const restaurantData = useSelector(selectRestaurantMergerData);
  const [restaurant, setRestaurants] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedRestaurants, setCheckedRestaurants] = useState([]);
  const isPopupOpen = useSelector(selectIsMergeRestaurantModalOpen);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPagesize] = useState(30);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getRestaurants = async () => {
    await dispatch(
      getAllRestaurant({
        page: pageNum || undefined,
        size: pageSize || undefined,
        searchQuery,
      })
    ).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setRestaurants(response?.payload?.restaurants);
          setTotalItems(response?.payload?.pagination?.totalItems);
          setLastPage(response?.payload?.pagination?.lastPage);
          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
  };

  useEffect(() => {
    getRestaurants();
    dispatch(mergeRestaurantModalHide());
    return () => {
      dispatch(mergeRestaurantModalHide());
    };
  }, [dispatch, pageNum, pageSize, searchQuery]);

  const paginate = (e) => {
    const selectedPage = e.selected;
    setPageNum(selectedPage + 1);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setPageNum(1);
  };

  const onCheckboxChange = (restaurantId, restaurantName) => {
    setCheckedIds((prevCheckedIds) => {
      if (prevCheckedIds.includes(restaurantId)) {
        return prevCheckedIds.filter((id) => id !== restaurantId);
      } else {
        return [...prevCheckedIds, restaurantId];
      }
    });

    setCheckedRestaurants((prevCheckedRestaurants) => {
      if (
        prevCheckedRestaurants.find(
          (restaurant) => restaurant.id === restaurantId
        )
      ) {
        return prevCheckedRestaurants.filter(
          (restaurant) => restaurant.id !== restaurantId
        );
      } else {
        return [
          ...prevCheckedRestaurants,
          { id: restaurantId, name: restaurantName },
        ];
      }
    });
  };

  const handleRestauarntChecked = async (restaurantId, restaurantName) => {
    onCheckboxChange(restaurantId, restaurantName);
  };

  const handleMergeClick = () => {
    dispatch(mergeRestaurantModalOpen());
  };

  const resetSelectionAndRefresh = () => {
    setCheckedIds([]);
    setPageNum(1);
  };

  useEffect(() => {}, [checkedIds]);

  const handleRestauarntLocationNavigation = async (id) => {
    dispatch(restaurantId(id));
    navigate(`/dashboard/restaurant-location-owner/view-detail/${id}`, {
      state: { referrer: "/dashboard/restaurant-merger" },
    });
  };

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Restaurant Merger</h2>
          <div className="iconSce dblock">
            <div className="searchPart">
              <div className="searchSec">
                <input
                  placeholder="Search"
                  name="myInput"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
              <div>
                {checkedIds.length > 1 ? (
                  <i className="bx bx-git-merge" onClick={handleMergeClick} />
                ) : (
                  <i className="bx bx-git-merge mergeDisabled" />
                )}
              </div>
            </div>

            {checkedIds.length > 0 && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    marginRight: "10px",
                    padding: "8px 0",
                    width: "350px",
                    background: "#ffe9ea",
                    textAlign: "center",
                  }}
                >
                  You have selected {checkedIds.length}{" "}
                  {checkedIds.length === 1 ? "restaurant" : "restaurants"}
                </p>
                <i className="bx bx-reset" onClick={resetSelectionAndRefresh} />
              </div>
            )}
          </div>
        </div>

        <div style={{ position: "relative" }} className="tableSec">
          {restaurantData?.isLoading && (
            <div className="tableLoader">
              <div className="spinnerLoad2fix">
                <Loading></Loading>
              </div>
            </div>
          )}
          {isDataLoaded && (
            <table>
              <thead>
                <tr>
                  <th style={{ width: "5%" }} scope="col"></th>
                  <th scope="col" style={{ width: "35%" }}>
                    Restaurant Name{" "}
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    Restaurant Category{" "}
                  </th>
                  <th scope="col" style={{ textAlign: "center", width: "30%" }}>
                    Branch Count{" "}
                  </th>
                  <th style={{ textAlign: "center", width: "10%" }} scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {restaurant?.length <= 0 && (
                  <tr style={{ borderBottom: "none" }}>
                    <td colSpan="4">
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "17px",
                          padding: "50px 0 0",
                        }}
                      >
                        No Data Found
                      </div>
                    </td>
                  </tr>
                )}
                {restaurant?.map((restaurant) => (
                  <tr
                    key={restaurant?.restaurantId}
                    onClick={() => {
                      handleRestauarntChecked(
                        restaurant?.restaurantId,
                        restaurant?.restaurantName
                      );
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: checkedIds.includes(
                        restaurant?.restaurantId
                      )
                        ? "#F6F6F6"
                        : "",
                    }}
                  >
                    <td>
                      <input
                        className="checkbox"
                        type="checkbox"
                        checked={checkedIds.includes(restaurant?.restaurantId)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          onCheckboxChange(
                            restaurant?.restaurantId,
                            restaurant?.restaurantName
                          )
                        }
                      />
                    </td>
                    <td data-label="Restaurant Name">
                      {restaurant?.restaurantName}
                    </td>
                    <td data-label="Restaurant Category">
                      {restaurant?.restaurantCategories?.length > 0
                        ? restaurant?.restaurantCategories
                            .map((category) => category.restaurantCategoryName)
                            .join(", ")
                        : "N/A"}
                    </td>
                    <td
                      data-label="Branch Count"
                      style={{ textAlign: "center" }}
                    >
                      {restaurant?.outlets}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div
                        style={{ cursor: "pointer", display: "inline-block" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRestauarntLocationNavigation(
                            restaurant?.restaurantId
                          );
                        }}
                      >
                        <i className="bx bx-show" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isPopupOpen && (
        <MergeRestaurantPopup
          closePopup={() => dispatch(mergeRestaurantModalHide())}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          setCheckedRestaurants={setCheckedRestaurants}
          checkedRestaurants={checkedRestaurants}
          resetSelectionAndRefresh={resetSelectionAndRefresh}
        />
      )}
      {totalItems > pageSize ? (
        <ReactPaginate
          previousLabel={<i className="bx bx-chevrons-left"></i>}
          nextLabel={<i className="bx bx-chevrons-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={lastPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={paginate}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={pageNum - 1}
        />
      ) : null}
    </Layout>
  );
};

export default RestaurantMerger;
