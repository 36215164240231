import { Fragment } from "react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextLogo from "../../../assets/images/text-logo.png";
import "./style.scss";
import { authData, userData } from "../../../store/selectors/authSelectors";
import MenuIcon from "../../../assets/images/menu-icon.svg";
import DealsIcon from "../../../assets/images/deals-icon.svg";
import ReportsIcon from "../../../assets/images/reports-icon.svg";
import LogoutIcon from "../../../assets/images/logout-icon.svg";
import RestaurantIcon from "../../../assets/images/restaurant-icon.svg";
import ManageitemIcon from "../../../assets/images/manage-item.svg";
import AdminIcon from "../../../assets/images/admin-icon.svg";
import DeletePopup from "../../components/delete-popup";
import { constant } from "../../constants";
import { logout } from "../../../store/reducers/auth";

const Layout = ({ children }) => {
  const user = useSelector(userData);
  const authLogoutData = useSelector(authData);
  const dispatch = useDispatch();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isDeletePopup, setIsDeletePopup] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = async () => {
    dispatch(logout()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          return;
  
        default:
          return;
      }
    });
  };

  return (
    <Fragment>
      <div className="dashboardWrapper">
        <div className="topbar">
          <i onClick={toggleSidebar} className="bx bx-menu"></i>
          <img src={TextLogo} alt="Logo" />
        </div>
        <div className={`sidebar ${isSidebarOpen ? "" : "close"}`}>
          <ul className="nav-links">
            {user.userType === "Administrator" &&
              user.userSettings?.isSuperAdmin && (
                <>
                  <li>
                    <a href="/dashboard/admin">
                      <i>
                        <img src={AdminIcon} alt="adminIcon" />
                      </i>
                      <span className="link_name">Admin</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/restaurant-category">
                      <i className="bx bxs-category-alt"></i>
                      <span className="link_name">Restaurant Category</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/restaurant">
                      <i>
                        <img src={RestaurantIcon} alt="restaurantIcon" />
                      </i>
                      <span className="link_name">Restaurants</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/restaurant-merger">
                      <i className="bx bx-merge"></i>
                      <span className="link_name">Restaurant Merger</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/manage-items">
                      <i>
                        <img src={ManageitemIcon} alt="restaurantIcon" />
                      </i>
                      <span className="link_name">Manage Items</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/import-csv-file">
                      <i className="bx bxs-file-import"></i>
                      <span className="link_name">Import CSV</span>
                    </a>
                  </li>
                </>
              )}
            {user.userType === "Administrator" &&
              !user.userSettings?.isSuperAdmin && (
                <>
                  <li>
                    <a href="/dashboard/restaurant-category">
                      <i className="bx bxs-category-alt"></i>
                      <span className="link_name">Restaurant Category</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/restaurant">
                      <i>
                        <img src={RestaurantIcon} alt="restaurantIcon" />
                      </i>
                      <span className="link_name">Restaurants</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/restaurant-merger">
                      <i className="bx bx-merge"></i>
                      <span className="link_name">Restaurant Merger</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/manage-items">
                      <i>
                        <img src={RestaurantIcon} alt="restaurantIcon" />
                      </i>
                      <span className="link_name">Manage Items</span>
                    </a>
                  </li>
                </>
              )}
            {user.userType === "Restaurant" &&
              !user.userSettings?.isSuperAdmin && (
                <>
                  <li>
                    <a href="/dashboard/restaurant-locations">
                      <i>
                        <img src={RestaurantIcon} alt="restaurantIcon" />
                      </i>
                      <span className="link_name">Restaurant Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/menu">
                      <i>
                        <img src={MenuIcon} alt="menuIcon" />
                      </i>
                      <span className="link_name">Menu</span>
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard/deals">
                      <i style={{ marginTop: "5px" }}>
                        <img src={DealsIcon} alt="dealsIcon" />
                      </i>
                      <span className="link_name">Deals</span>
                    </a>
                  </li>
                </>
              )}
            <li>
              <a href="/dashboard/report">
                <i style={{ marginTop: "3px" }}>
                  <img src={ReportsIcon} alt="reportsIcon" />
                </i>
                <span className="link_name">Reports</span>
              </a>
            </li>
          </ul>
          <ul className="nav-links bottomLink">
            <li>
              <a
                onClick={() => {
                  setIsDeletePopup(true);
                }}
              >
                <i style={{ marginTop: "5px" }}>
                  <img src={LogoutIcon} alt="logoutIcon" />
                </i>
                <span className="link_name">Logout</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="home-section">{children}</div>
      </div>
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => setIsDeletePopup(false)}
          handleDelete={() => handleLogout()}
          popuploading={authLogoutData?.isLoading}
          title="Logout Confirmation"
          description="Are you sure you want to Logout?"
          buttonName="Logout"
        />
      )}
    </Fragment>
  );
};

export default Layout;
