import { parsePhoneNumberFromString } from 'libphonenumber-js';

function validatePhoneNumber(phoneNumber, countryCode) {
    if (!phoneNumber || !countryCode) return false;
    
    // Combine the country code and phone number to form the international format
    const fullPhoneNumber = `+${countryCode}${phoneNumber}`;
    const parsedNumber = parsePhoneNumberFromString(fullPhoneNumber);
  
    return parsedNumber && parsedNumber.isValid();
  }

  function formatPhoneNumber(phoneCountryCode, phoneNumber) {
    if (!phoneNumber) return "NA";
  
    // Ensure the phone number has 10 digits
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    // Check if phoneCountryCode already contains "+"
    const countryCode = phoneCountryCode.startsWith('+') ? phoneCountryCode : `+${phoneCountryCode}`;
    
    if (match) {
      return `(${countryCode}) (${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    return phoneNumber;
  }

  function formatDateTime (dateString) {
    const date = new Date(dateString);
    
    // Get formatted date as MM/DD/YYYY
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    
    // Get formatted time as hh:mm am/pm
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  
    return `${formattedDate}, ${formattedTime}`;
  };  

  export { validatePhoneNumber, formatPhoneNumber, formatDateTime };