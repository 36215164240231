import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addDealModalIsOpen: false,
  editDealModalIsOpen: false,
  successModalIsOpen: false,
  deleteModalIsOpen: false,
  deleteRestaurantProfileModalIsOpen: false,
  createAdminModalIsOpen: false,
  createRestaurantModalIsOpen: false,
  addMenuItemModalIsOpen: false,
  editMenuItemModalIsOpen: false,
  addCategoryModalIsOpen: false,
  editCategoryModalIsOpen: false,
  filterCategoryModalIsOpen: false,
  addRestaurantLocationModalIsOpen: false,
  editRestaurantLocationModalIsOpen: false,
  editRestaurantProfileModalIsOpen: false,
  changePasswordModalIsOpen: false,
  deleteLocationModalIsOpen: false,
  createRestaurantCategoryModalIsOpen: false,
  editRestaurantCategoryModalIsOpen: false,
  mergeRestaurantModalIsOpen: false,
};

const { reducer, actions } = createSlice({
  name: "modal",
  initialState,
  reducers: {
    addDealModalOpen: (state) => {
      state.addDealModalIsOpen = true;
    },
    addDealModalHide: (state) => {
      state.addDealModalIsOpen = false;
    },
    editDealModalOpen: (state) => {
      state.editDealModalIsOpen = true;
    },
    editDealModalHide: (state) => {
      state.editDealModalIsOpen = false;
    },
    successModalOpen: (state) => {
      state.successModalIsOpen = true;
    },
    successModalHide: (state) => {
      state.successModalIsOpen = false;
    },
    deleteModalOpen: (state) => {
      state.deleteModalIsOpen = true;
    },
    deleteModalHide: (state) => {
      state.deleteModalIsOpen = false;
    },
    deleteRestaurantProfileModalOpen: (state) => {
      state.deleteRestaurantProfileModalIsOpen = true;
    },
    deleteRestaurantProfileModalHide: (state) => {
      state.deleteRestaurantProfileModalIsOpen = false;
    },
    deleteLocationModalOpen: (state) => {
      state.deleteLocationModalIsOpen = true;
    },
    deleteLocationModalHide: (state) => {
      state.deleteLocationModalIsOpen = false;
    },
    createAdminModalOpen: (state) => {
      state.createAdminModalIsOpen = true;
    },
    createAdminModalHide: (state) => {
      state.createAdminModalIsOpen = false;
    },
    createRestaurantModalOpen: (state) => {
      state.createRestaurantModalIsOpen = true;
    },
    createRestaurantModalHide: (state) => {
      state.createRestaurantModalIsOpen = false;
    },
    addMenuItemModalOpen: (state) => {
      state.addMenuItemModalIsOpen = true;
    },
    addMenuItemModalHide: (state) => {
      state.addMenuItemModalIsOpen = false;
    },
    editMenuItemModalOpen: (state) => {
      state.editMenuItemModalIsOpen = true;
    },
    editMenuItemModalHide: (state) => {
      state.editMenuItemModalIsOpen = false;
    },
    addCategoryModalOpen: (state) => {
      state.addCategoryModalIsOpen = true;
    },
    addCategoryModalHide: (state) => {
      state.addCategoryModalIsOpen = false;
    },
    editCategoryModalOpen: (state) => {
      state.editCategoryModalIsOpen = true;
    },
    editCategoryModalHide: (state) => {
      state.editCategoryModalIsOpen = false;
    },
    filterCategoryModalOpen: (state) => {
      state.filterCategoryModalIsOpen = true;
    },
    filterCategoryModalHide: (state) => {
      state.filterCategoryModalIsOpen = false;
    },
    addRestaurantLocationModalOpen: (state) => {
      state.addRestaurantLocationModalIsOpen = true;
    },
    addRestaurantLocationModalHide: (state) => {
      state.addRestaurantLocationModalIsOpen = false;
    },
    editRestaurantLocationModalOpen: (state) => {
      state.editRestaurantLocationModalIsOpen = true;
    },
    editRestaurantLocationModalHide: (state) => {
      state.editRestaurantLocationModalIsOpen = false;
    },
    editRestaurantProfileModalOpen: (state) => {
      state.editRestaurantProfileModalIsOpen = true;
    },
    editRestaurantProfileModalHide: (state) => {
      state.editRestaurantProfileModalIsOpen = false;
    },
    changePasswordModalOpen: (state) => {
      state.changePasswordModalIsOpen = true;
    },
    changePasswordModalHide: (state) => {
      state.changePasswordModalIsOpen = false;
    },
    createRestaurantCategoryModalOpen: (state) => {
      state.createRestaurantCategoryModalIsOpen = true;
    },
    createRestaurantCategoryModalHide: (state) => {
      state.createRestaurantCategoryModalIsOpen = false;
    },
    editRestaurantCategoryModalOpen: (state) => {
      state.editRestaurantCategoryModalIsOpen = true;
    },
    editRestaurantCategoryModalHide: (state) => {
      state.editRestaurantCategoryModalIsOpen = false;
    },
    mergeRestaurantModalOpen: (state) => {
      state.mergeRestaurantModalIsOpen = true;
    },
    mergeRestaurantModalHide: (state) => {
      state.mergeRestaurantModalIsOpen = false;
    },
  },
});

export const {
  addDealModalOpen,
  addDealModalHide,
  editDealModalOpen,
  editDealModalHide,
  successModalOpen,
  successModalHide,
  deleteModalOpen,
  deleteModalHide,
  deleteRestaurantProfileModalOpen,
  deleteRestaurantProfileModalHide,
  createAdminModalOpen,
  createAdminModalHide,
  createRestaurantModalOpen,
  createRestaurantModalHide,
  addMenuItemModalOpen,
  addMenuItemModalHide,
  editMenuItemModalOpen,
  editMenuItemModalHide,
  addCategoryModalOpen,
  addCategoryModalHide,
  editCategoryModalOpen,
  editCategoryModalHide,
  filterCategoryModalOpen,
  filterCategoryModalHide,
  addRestaurantLocationModalOpen,
  addRestaurantLocationModalHide,
  editRestaurantLocationModalOpen,
  editRestaurantLocationModalHide,
  editRestaurantProfileModalOpen,
  editRestaurantProfileModalHide,
  changePasswordModalOpen,
  changePasswordModalHide,
  deleteLocationModalOpen,
  deleteLocationModalHide,
  createRestaurantCategoryModalHide,
  createRestaurantCategoryModalOpen,
  editRestaurantCategoryModalOpen,
  editRestaurantCategoryModalHide,
  mergeRestaurantModalOpen,
  mergeRestaurantModalHide,
} = actions;
export default reducer;
