import React, { useState, useEffect } from "react";
import Layout from "../../../layouts/dashboard-layout";
import ReactPaginate from "react-paginate";
import Loading from "../../../components/loading";
import "./admin.scss";
import DeletePopup from "../../../components/delete-popup";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin, getAdmin } from "../../../../store/reducers/admin";
import { selectAdminData } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import CreateAdminPopup from "./createAdminPopup";
import { selectIsCreateAdminModalOpen, selectIsDeleteModalOpen } from "../../../../store/selectors/modalSelectors";
import { createAdminModalHide, createAdminModalOpen, deleteModalHide, deleteModalOpen } from "../../../../store/reducers/modals";
import { formatPhoneNumber } from "../../../components/formatAndValidate";

const Admin = () => {
  const adminData = useSelector(selectAdminData);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const isPopupOpen = useSelector(selectIsCreateAdminModalOpen);
  const [admins, setAdmins] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const dispatch = useDispatch();
  const getAdmins = async (page = pageNum) => {
    await dispatch(getAdmin({ pageNum: page, pageSize, searchQuery })).then(
      (response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            setTotalItems(response?.payload?.pagination?.totalItems);
            setLastPage(response?.payload?.pagination?.lastPage);
            const sortedAdmins = [...response?.payload?.administrators].sort(
              (a, b) => a.fullName.localeCompare(b.fullName)
            );
            setAdmins(sortedAdmins);
            setIsDataLoaded(true);
            return;

          default:
            return;
        }
      });
  };

  useEffect(() => {
    getAdmins();
    dispatch(createAdminModalHide());
    dispatch(deleteModalHide());
    return () => {
      dispatch(createAdminModalHide());
      dispatch(deleteModalHide());
      };
  }, [dispatch, pageNum, pageSize, searchQuery]);

  const paginate = (e) => {
    const selectedPage = e.selected;
    setPageNum(selectedPage + 1);
  };

  const handleDeleteClick = (adminId) => {
    setSelectedAdminId(adminId);
    dispatch(deleteModalOpen());
  };

  const removeAccess = async (administratorId) => {
    dispatch(deleteAdmin(administratorId)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          getAdmins();
          return;
  
        default:
          return;
      }
    });
  };

  const sortAdmins = (criterion, direction) => {
    if (!Array.isArray(admins)) {
      return;
    }
    const sortedAdmins = [...admins].sort((a, b) => {
      const aValue = a[criterion] || "";
      const bValue = b[criterion] || "";
      if (direction === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });
    setAdmins(sortedAdmins);
  };

  useEffect(() => {
    sortAdmins(sortBy, sortDirection);
  }, [sortBy, sortDirection]);

  const handleSort = (criterion) => {
    setSortBy(criterion);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Admins</h2>
          <div className="iconSce">
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div>
              <i onClick={() => dispatch(createAdminModalOpen())} className="bx bx-plus"></i>
            </div>
          </div>
        </div>

        <div style={{ position: "relative" }} className="tableSec">
          {adminData?.isLoading && (
            <div className="tableLoader">
              <div className="spinnerLoad">
                <Loading></Loading>
              </div>
            </div>
          )}
          {isDataLoaded && (
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    Admin Name{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("fullName")}
                    />
                  </th>
                  <th scope="col">
                    Admin Email{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("emailAddress")}
                    />
                  </th>
                  <th scope="col">
                    Admin Phone Number{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("phoneNumber")}
                    />
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {admins?.length <= 0 && (
                  <tr style={{ borderBottom: "none" }}>
                    <td colSpan="3">
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "17px",
                          padding: "50px 0 0",
                        }}
                      >
                        No Data Found
                      </div>
                    </td>
                  </tr>
                )}

                {admins?.map((admin) => (
                  <tr key={admin?.userId}>
                    <td data-label="Admin Name">{admin?.fullName}</td>
                    <td data-label="Admin Email">{admin?.emailAddress}</td>
                    <td className="textCenter1" data-label="Admin Phone Number">
                      {admin?.phoneNumber ? formatPhoneNumber(admin?.phoneCountryCode, admin?.phoneNumber) : "N/A"}
                    </td>
                    <td className="textEnd" data-label="Actions">
                      <div
                        className="raBtn"
                        onClick={() => handleDeleteClick(admin?.userId)}
                      >
                        Remove Access
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isPopupOpen && (
        <CreateAdminPopup
          closePopup={() => dispatch(createAdminModalHide())}
          getAdmins={getAdmins}
          adminData={adminData}
        />
      )}
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() => removeAccess(selectedAdminId)}
          popuploading={adminData?.isPopupLoading}
          title="Remove Access Confirmation"
          description="Are you sure you want to Remove the Access?"
          buttonName="Yes, remove access!"
        />
      )}
      {totalItems > pageSize ? (
        <ReactPaginate
          previousLabel={<i className="bx bx-chevrons-left"></i>}
          nextLabel={<i className="bx bx-chevrons-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={lastPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={paginate}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      ) : null}
    </Layout>
  );
};

export default Admin;
