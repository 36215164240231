import React, { useState } from "react";
import Layout from "../../../../layouts/dashboard-layout";
import "./restaurant-details.scss";
import "react-phone-input-2/lib/style.css";
import { selectRestaurantLocationData } from "../../../../../store/selectors/dashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import BackArrow from "../../../../../assets/images/back-arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addDealModalOpen,
  addMenuItemModalOpen,
  addRestaurantLocationModalOpen,
  filterCategoryModalOpen,
} from "../../../../../store/reducers/modals";
import RestaurantLocationDetail from "./restaurant-locations-tab/restaurant-locations";
import DealDetail from "./restaurant-deals-tab/deal";
import MenuAdmin from "./restaurant-menu-tab/menuAdmin";
import FilterIcon from "../../../../../assets/images/filter-icon.svg";

const RestaurantDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const referrer = location.state?.referrer;
  const initialTab = location.state?.activeTab || "restaurant-loactions-tab";
  const [activeTab, setActiveTab] = useState(initialTab);
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [filteredDataAdmin, setFilteredDataAdmin] = useState([]);
  const [searchQueryAdmin, setSearchQueryAdmin] = useState("");
  const [isEditModeAdmin, setIsEditModeAdmin] = useState(false);
  const [prevEditMode, setPrevEditMode] = useState(false);

  const [selectedCategoriesForAdmin, setSelectedCategoriesForAdmin] = useState(
    [],
  );

  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantName =
    restaurantDetailData?.restaurantDetails?.restaurant?.restaurantName;

  const handleSearch = async (e) => {
    if (activeTab === "restaurant-loactions-tab") {
      const query = e.target.value.toLowerCase();
      setSearchQueryAdmin(query);
      setLoadingAdmin(true);
      try {
        const filtered =
          restaurantDetailData?.restaurantDetails?.restaurant?.addresses?.filter(
            (address) =>
              address?.streetAddress.toLowerCase().includes(query) ||
              address?.phoneNumber.toLowerCase().includes(query),
          );
        setTimeout(() => {
          setFilteredDataAdmin(filtered);
          setLoadingAdmin(false);
        }, 1000);
      } catch (error) {
        console.log(error);
        setLoadingAdmin(false);
      }
    }
    if (activeTab === "menu-tab") {
      setSearchQueryAdmin(e.target.value);
    }
  };

  const handleAddFunctionality = () => {
    if (activeTab === "restaurant-loactions-tab") {
      dispatch(addRestaurantLocationModalOpen());
    }
    if (activeTab === "deal-tab") {
      dispatch(addDealModalOpen());
    }
    if (activeTab === "menu-tab") {
      dispatch(addMenuItemModalOpen());
    }
  };

  const toggleEditMode = () => {
    if (!isEditModeAdmin) {
      setPrevEditMode(isEditModeAdmin);
    }
    setIsEditModeAdmin(!isEditModeAdmin);
  };
  const toggleToPrevEditMode = () => {
    setIsEditModeAdmin(prevEditMode);
  };

  const handleBackNavigation = () => {
    if (referrer === "/dashboard/restaurant-merger") {
      navigate("/dashboard/restaurant-merger");
    } else {
      navigate("/dashboard/restaurant");
    }
  };

  // document.body.style.overflow = "hidden";

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <div className="topSecFlx">
            <img
              style={{ marginRight: "15px", cursor: "pointer" }}
              src={BackArrow}
              alt="back arrow"
              onClick={handleBackNavigation}
            />
            <div style={{ width: "90%" }}>
              <h2> {restaurantName}</h2>
            </div>
          </div>
          <div className="iconSce">
            {activeTab === "menu-tab" && (
              <div className="filterSec">
                {selectedCategoriesForAdmin?.length > 0 && (
                  <div className="countSec"></div>
                )}
                <Link>
                  <img
                    src={FilterIcon}
                    onClick={() => dispatch(filterCategoryModalOpen())}
                    alt="FilterIcon"
                  />
                </Link>
              </div>
            )}
            {(activeTab === "restaurant-loactions-tab" ||
              activeTab === "menu-tab") && (
              <div className="searchSec">
                <input
                  placeholder="Search"
                  name="myInput"
                  value={searchQueryAdmin}
                  onChange={handleSearch}
                />
              </div>
            )}
            {activeTab === "menu-tab" && (
              <div>
                {isEditModeAdmin ? (
                  <div style={{ marginTop: "13px" }}>
                    <a className="doneBtn" onClick={toggleToPrevEditMode}>
                      Done
                    </a>
                  </div>
                ) : (
                  <div style={{ marginTop: "0px" }}>
                    <i className="bx bxs-pencil" onClick={toggleEditMode}></i>
                  </div>
                )}
              </div>
            )}
            <div>
              <i className="bx bx-plus" onClick={handleAddFunctionality}></i>
            </div>
          </div>
        </div>

        <div className="tabSecrow">
          <div className="tabs">
            <div
              className={
                activeTab === "restaurant-loactions-tab"
                  ? "selectedTabs detailsTabs"
                  : "detailsTabs"
              }
              onClick={() => setActiveTab("restaurant-loactions-tab")}
            >
              <span>Restaurant</span> Locations
            </div>

            <div
              className={
                activeTab === "menu-tab"
                  ? "selectedTabs detailsTabs"
                  : "detailsTabs"
              }
              onClick={() => setActiveTab("menu-tab")}
            >
              <span>Restaurant</span> Menu
            </div>

            <div
              className={
                activeTab === "deal-tab"
                  ? "selectedTabs detailsTabs"
                  : "detailsTabs"
              }
              onClick={() => setActiveTab("deal-tab")}
            >
              <span>Restaurant’s</span> Deals
            </div>

            {activeTab === "restaurant-loactions-tab" && (
              <RestaurantLocationDetail
                loadingAdmin={loadingAdmin}
                searchQueryAdmin={searchQueryAdmin}
                filteredDataAdmin={filteredDataAdmin}
              />
            )}
            {activeTab === "deal-tab" && <DealDetail />}
            {activeTab === "menu-tab" && (
              <MenuAdmin
                isEditModeAdmin={isEditModeAdmin}
                searchQueryAdmin={searchQueryAdmin}
                setSelectedCategoriesForAdmin={setSelectedCategoriesForAdmin}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RestaurantDetails;
