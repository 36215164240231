import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Logo from "../../../../assets/images/logo.png";
import {
  refreshToken,
  setToken,
  storedData,
} from "../../../../store/reducers/authSlice";
import { authData, userType } from "../../../../store/selectors/authSelectors";
import Loading from "../../../components/loading";
import "./login.scss";
import { login } from "../../../../store/reducers/auth";
import { constant } from "../../../constants";

const Login = () => {
  const user = useSelector(userType);
  const [initialUserType, setInitialUserType] = useState(user?.userTypeValue);
  const [initialUserTypeLabel, setInitialUserTypeLabel] = useState(
    user?.userTypeLabel,
  );
  const dispatch = useDispatch();
  const authLoginData = useSelector(authData);

  const handleLogin = async (values) => {
    const payload = {
      emailAddress: values?.email?.toLowerCase()?.trim(),
      password: values?.password,
      userType: initialUserType,
    };

    dispatch(login(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const data = response?.payload;
          if (data?.user?.userType === "Customer") {
            toast.error("Please login from the app as a customer");
          }
          dispatch(setToken(data?.tokens?.accessToken));
          dispatch(refreshToken(data?.tokens?.refreshToken));
          dispatch(storedData(data?.user));
          toast.success(data?.message || "Login successfully");
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="login-wrapper">
      <div className="login-row">
        <div className="left-section">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="right-section">
          <div className="login-container">
            <h2>Login</h2>
            <p>You are logging in as an {initialUserTypeLabel}</p>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email")
                  .required("Email Address is required")
                  .trim(),
                password: Yup.string().required("Password is required"),
              })}
              onSubmit={(values, { setSubmitting }) =>
                handleLogin(values, setSubmitting)
              }
            >
              {({ isSubmitting, isValid, errors }) => {
                return (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email@example.com"
                        component={Input}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Your Password"
                        component={Input}
                      />
                      <div className="forgot-password-container">
                        <a href="/forgot-password" className="forgot-password">
                          Forgot Password?
                        </a>
                      </div>
                    </div>

                    <div className="logbtnSec">
                      <Button
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        className="login-button"
                      >
                        {authLoginData?.isLoading && <Loading></Loading>}
                        {isSubmitting ? "Logging in..." : "Login"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
