export const selectAdminData = (state) => state.admin;
export const selectRestaurantData = (state) => state.restaurant;
export const selectRestaurantMergerData = (state) => state.restaurantMerger;
export const selectRestaurantDetailsData = (state) => state.restaurantDetail;
export const selectMenuItemsData = (state) => state.menuItem;
export const selectfoodCategoryData = (state) => state.foodCategory;
export const selectfoodCategoryByIdData = (state) => state.foodCategoryById;
export const selectDealData = (state) => state.deal;
export const selectFoodItemByIdData = (state) => state.foodItemById;
export const selectRestaurantLocationData = (state) => state.restaurantLocationOwner;
export const selectImportCSVFileData = (state) => state.importCSV;
export const selectRestaurantCategory = (state) => state.restaurantCategory;
export const selectFoodItems = (state) => state.foodItems;