import React from "react";
import MenuComponent from "../../../menu/mainMenu";

const MenuAdmin = ({
  searchQueryAdmin,
  isEditModeAdmin,
  setSelectedCategoriesForAdmin = () => {},
}) => {
  return (
    <div className="tab tabscroll">
      <div class="scrollbar2" id="style-4">
        <div class="force-overflow">
          <MenuComponent
            searchQueryAdmin={searchQueryAdmin}
            isEditModeAdmin={isEditModeAdmin}
            setSelectedCategoriesForAdmin={setSelectedCategoriesForAdmin}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuAdmin;
