import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { thunk } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import authReducer from "./reducers/authSlice";
import timerReducer from "./reducers/timerSlice";
import adminReducer from "./reducers/admin";
import restaurantReducer from "./reducers/restaurant";
import restaurantDetailsReducer from "./reducers/restaurantDetail";
import menuItemsReducer from "./reducers/menu";
import foodCategoriesByIdReducer from "./reducers/foodCategoryById";
import foodCategoryReducer from "./reducers/foodCategory";
import authMeReducer from "./reducers/authMe";
import foodItemByIdReducer from "./reducers/foodItemsById";
import authAdminByReducer from "./reducers/auth";
import dealReducer from "./reducers/deal";
import modalReducer from "./reducers/modals";
import restaurantLocationOwnerReducer from "./reducers/restaurantLocationDetail";
import importFoodCSVReducer from "./reducers/importFoodCSV";
import restaurantCategoryReducer from "./reducers/restaurantCategory";
import foodItemsReducer from "./reducers/manageFoodItems";
import restaurantMergerReducer from "./reducers/restaurantMerger";
import restaurantAddressReducer from "./reducers/restaurantAddress";

const reducers = combineReducers({
  auth: authReducer,
  email: timerReducer,
  modal: modalReducer,
  admin: adminReducer,
  authAdmin: authAdminByReducer,
  restaurant: restaurantReducer,
  restaurantMerger: restaurantMergerReducer,
  restaurantDetail: restaurantDetailsReducer,
  menuItem: menuItemsReducer,
  foodCategoryById: foodCategoriesByIdReducer,
  foodCategory: foodCategoryReducer,
  authMe: authMeReducer,
  foodItemById: foodItemByIdReducer,
  deal: dealReducer,
  restaurantLocationOwner: restaurantLocationOwnerReducer,
  importCSV: importFoodCSVReducer,
  restaurantCategory: restaurantCategoryReducer,
  foodItems: foodItemsReducer,
  restaurantAddress: restaurantAddressReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "email",
    "modal",
    "admin",
    "restaurantReducer",
    "restaurantDetail",
    "menuItem",
    "foodCategoryById",
    "foodCategory",
    "authMe",
    "foodItemById",
    "authAdmin",
    "deal",
    "restaurantLocationOwner",
    "importCSV",
    "restaurantCategory",
    "foodItems",
    "restaurantMerger",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export const persistor = persistStore(store);
